import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout, { Footer } from '../components/Layout'
import { ArticleHeader, Section, Article } from '../components/Article'

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { ne: "document" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          slug
          date
          title
          author
          description
        }
      }
    }
  }
`

const ListItem = styled.div`
  h2 {
    margin-bottom: 0;
  }
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const Author = styled.p`
  font-style: italic;
  font-size: 0.8rem;
`

const Page = ({ data }) => {
  const articles = data.allMarkdownRemark.nodes

  return (
    <Layout title="Artiklar">
      <Article>
        <ArticleHeader title="Artiklar" />
        <Section>
          <div>
            {articles.map(article => (
              <ListItem key={article.frontmatter.slug}>
                <h2>
                  <a href={`/${article.frontmatter.slug}`}>
                    {article.frontmatter.title}
                  </a>
                </h2>

                <Author>
                  av {article.frontmatter.author}{' '}
                  {new Date(article.frontmatter.date).toLocaleDateString('sv')}
                </Author>
                <p>{article.frontmatter.description}</p>
              </ListItem>
            ))}
          </div>
        </Section>
        <Footer>
          <menu>
            <div>
              <a href="/">sciigo.com</a>
            </div>
            <div>|</div>
            <div>
              <a href="mailto:hello@sciigo.com">hello@sciigo.com</a>
            </div>
          </menu>
        </Footer>
      </Article>
    </Layout>
  )
}

export default Page
